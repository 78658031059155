// JS file which is included inline in the <head> for rendering above the fold content
import './utils/breakpoints';
import initResponsiveBackgroundImages from './utils/responsive-bg-image';
import setFullHeightClass from './components/container';
import objectFitImages from 'object-fit-images';

document.addEventListener('DOMContentLoaded', function () {
  objectFitImages();
  setFullHeightClass();
  initResponsiveBackgroundImages();
});
