// add a full height class to the feature container if a slider is on the page
// this will scale the feauture container to 100vh
export default function setFullHeightClass() {
  let elements = document.querySelectorAll('.container-feature .slider, .container-feature .feature-grid');
  if (elements.length) {
    if (elements[0].classList.contains('full-height')) {
      elements[0].parentNode.classList.add('full-height');
    } else {
      elements[0].parentNode.classList.add('small-container');
    }

    if (elements[0].classList[0] == 'feature-grid') {
      elements[0].parentNode.classList.add('full-height-grid');
    }

    const mobileUA = /Android|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (mobileUA) {
      const header = document.getElementsByClassName('header')[0];
      const height = window.innerHeight - (header ? header.offsetHeight : 0);
      elements[0].parentNode.style.height = `${height}px`;
    }
  }
}
